<script setup>
import {ref, watch} from "vue";

const input = ref('')
const emit = defineEmits(['update:modelValue', 'blur'])

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: null
    },
    hasError: {
        type: Boolean,
        required: false,
        default: false
    },
    errorText: {
        type: String,
        required: false,
        default: ''
    },
    wrapperClass: {
        type: String,
        required: false,
        default: ''
    },
    inputType: {
        type: String,
        required: true
    },
    inputId: {
        type: String,
        required: false,
        default: null
    },
    inputClass: {
        type: String,
        required: false,
        default: ''
    },
    labelText: {
        type: String,
        required: true
    },
    labelClass: {
        type: String,
        required: false,
        default: ''
    },
    isRequired: {
        type: Boolean,
        required: false,
        default: false,
    },
    autocomplete: {
        type: String,
        required: false,
        default: null
    },
    inputDisabled: {
        type: Boolean,
        default: false
    },
    inputStyle: {
        type: String,
        required: false,
        default: null
    },
    inputMax: {
        type: String,
        required: false,
        default: null
    },
})
watch(
    () => props.modelValue,
    (newVal) => {
        input.value = newVal
    },
    { immediate: true }
)

</script>
<template>
    <div class="form-floating" :class="wrapperClass">
        <input
            :type="inputType"
            :id="props.inputId ? props.inputId : null"
            class="form-control"
            :autocomplete="autocomplete"
            :class="[{ 'is-invalid': props.hasError, 'is-required': props.isRequired }, inputClass]"
            :placeholder="labelText"
            v-model="input"
            :style="inputStyle"
            :max="inputMax"
            @input="emit('update:modelValue', input)"
            @change="emit('update:modelValue', input)"
            :disabled="inputDisabled ? true : null"
            @blur="emit('blur')"
        >
        <label
            :for="props.inputId ? props.inputId : null"
            :class="{ 'required': props.isRequired }"
        >
            {{ labelText }}
        </label>
        <div v-show="props.hasError" class="invalid-feedback">{{ errorText }}</div>
    </div>
</template>
